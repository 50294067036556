.fuego
	@import "../../plugins/translations/admin/theme"
	--brand-accent-color: #1EB46D
	--navbar-background-color: var(--brand-accent-color)
	cursor: default

	.cui-layout
		&-space
			.layout-content
				&-side
					width: auto
					position: fixed
					z-index: 100
					top: 4.75em
					margin: 0
					right: 2em
					pointer-events: none

					.cui-box
						&, &-content
							display: contents

					.cui-saveControl, .cui-button
						pointer-events: auto

	.cui-editorCanvas
		box-shadow: none
		//border-bottom: solid 1px #E5E5EC
		border-radius: 0
		background: transparent

		h1[data-slate-node]
			font-size: 2em
		h2[data-slate-node]
			font-size: 1.75em
		h3[data-slate-node]
			font-size: 1.6em
		h4[data-slate-node]
			font-size: 1.4em

	// Table overflow hotfix
	.cui-table
		.cui-table-cell:nth-child(2)
			white-space: initial

	.cui-input[type="color"]
		width: 3.4em
		padding: 0 0.15em

	.sideDimensions-dimensions-in
		margin-top: 1em
